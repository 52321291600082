<template>
  <div>
    <div class="flex xs12">
      <va-card title="Email Templates">
        <div class="row align--center">
          <div class="flex xs12 md6">
            <va-input
              :value="term"
              :placeholder="'Search Emails'"
              @input="search"
              removable
            >
              <va-icon
                name="fa fa-search"
                slot="prepend"
              />
            </va-input>
          </div>
          <div class="flex xs12 md3 offset--md3">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>
        <va-data-table
          :fields="templateFields"
          :data="templatesFilteredData"
          :loading="loading"
          @row-clicked="(row)=>{$router.push({name:'editTemplate',params:{id:row.id}})}"
          hoverable
          :perPage="parseInt(perPage)"
          clickable
        >
          <template slot="name" slot-scope="props">
            <span class="capitalize">{{props.rowData.id}}</span>
          </template>
          <template slot="subject" slot-scope="props">
            <span class="capitalize">{{props.rowData.subject}}</span>
          </template>
          <template slot="updatedAt" slot-scope="props">
            <span class="capitalize">{{props.rowData.updatedAt}}</span>
          </template>
        </va-data-table>
      </va-card>
    </div>
  </div>
</template>
<script>
import firebase from 'firebase';
import {debounce} from 'lodash';
export default {
  data: function() {
    return {
      loading: true,
      templates: [],
      perPage: '10',
      term: '',
      perPageOptions: ['10', '20', '50', '100'],
    };
  },
  created() {
    firebase.firestore().collection('emailTemplates').get().then((templates)=>{
      this.templates = templates.docs.map((template)=>{
        const data = template.data();
        data.timestamp = data.updatedAt ? data.updatedAt?.seconds : 0;
        data.updatedAt = data.updatedAt ? data.updatedAt.toDate().toLocaleString() : 'N/A';
        return data;
      });
      this.loading = false;
    });
  },
  methods: {
    search: debounce(function(term) {
      /* eslint-disable */
      this.term = term;
    }, 100),
  },
  computed: {
    templateFields() {
      return [{
        name: '__slot:name',
        title: 'Template Name',
      }, {
        name: '__slot:subject',
        title: 'Subject',
      }, {
        name: '__slot:updatedAt',
        sortField: 'updatedAt',
        title: 'Last Update',
      }];
    },
    templatesFilteredData() {
      if (!this.term || this.term.length < 1) {
        // sort by timestamp.seconds desc
        this.templates.sort((a, b) => {
          return b.timestamp - a.timestamp;
        });
        return this.templates;
      }

      // filter by user
      const filteredByUser = this.templates.filter((item) => {
        let returnUser = false;
        if (item.subject) {
          if (item.subject.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnUser = true;
          }
        }
        return returnUser;
      });

      // sort filtered results by timestamp.seconds desc
      const sortedFilteredData = filteredByUser.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });

      return sortedFilteredData;
    },
  },
};
</script>
<style scoped>

.capitalize {
  text-transform: capitalize;
}

</style>